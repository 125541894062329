// Mastodon Modern theme by Freeplay! Check the original repo for more info: https://git.gay/freeplay/Mastodon-Modern
// Everything in the "modern" directory is licensed under a Creative Commons Attribution-ShareAlike 4.0 International License: http://creativecommons.org/licenses/by-sa/4.0/

@import 'variables';
@import 'index';
@import 'modern/style';
@import 'modern/glitch-fixes';

:root {
  --accent-clr: #8c8dff;
  --accent-deep-clr: #5455ff;
  --bg-primary: #15171c;
  --bg-secondary: #1d2026;
  --bg-tertiary: #101115;
  --bg-quaternary: #0e0f13;
  --bg-selected: #1c1f26;
}

body {
  font-family: 'DM Sans', mastodon-font-sans-serif, sans-serif;
}
